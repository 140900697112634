import React, { useEffect, useState } from "react";
import "./filter.css";
import Header from "../../layout/header/Header";
import { NavLink, useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../context/context";
import { baseURL } from "../../config";
import { axiosInstance } from "../../config";

const Filter = () => {
  const { file, currentUser } = useGlobalContext();
  const [post, setPost] = useState([]);
  const [follower, setFollower] = useState([]);
  const [following, setFollowing] = useState([]);
  const navigate = useNavigate();
  const PF = `${baseURL}profilePicture/${currentUser?.profilePhoto}`;

  // get follower
  useEffect(() => {
    const fatch = async () => {
      try {
        const { data } = await axiosInstance.get(
          `api/post/getFollowing/${currentUser.id}`
        );
        setFollower(data);
      } catch (error) {
        console.log(error);
      }
    };
    fatch();
  }, [currentUser]);

  // get following
  useEffect(() => {
    const fatch = async () => {
      try {
        const { data } = await axiosInstance.get(
          `api/post/getFollow/${currentUser.id}`
        );
        setFollowing(data);
      } catch (error) {
        console.log(error);
      }
    };
    fatch();
  }, [currentUser]);

  // get all post of logged user
  useEffect(() => {
    const fatch = async () => {
      try {
        const { data } = await axiosInstance.get(`api/post/${currentUser.id}`);
        setPost(data);
      } catch (error) {
        console.log(error);
      }
    };
    fatch();
  }, [currentUser]);

  useEffect(() => {
    if (file === null) {
      navigate("/createpost");
    }
  }, [file]);

  return (
    <>
      <Header />
      <div className="filter">
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-12">
              <div className="filter-main-img">
                <h5>Create Post</h5>
                <img src={file && URL.createObjectURL(file)} alt="img" />
              </div>
              <div className="filter-section">
                <h5>Filters</h5>
                <div>
                  <h6>Orginal</h6>
                  <img src={file && URL.createObjectURL(file)} alt="img" />
                </div>
                <div>
                  <h6>B&W</h6>
                  <img src={file && URL.createObjectURL(file)} alt="img" />
                </div>
                <div>
                  <h6>Sharp</h6>
                  <img src={file && URL.createObjectURL(file)} alt="img" />
                </div>
                <div>
                  <h6>Orginal</h6>
                  <img src={file && URL.createObjectURL(file)} alt="img" />
                </div>
                <div>
                  <h6>B&W</h6>
                  <img src={file && URL.createObjectURL(file)} alt="img" />
                </div>
                <div>
                  <h6>Orginal</h6>
                  <img src={file && URL.createObjectURL(file)} alt="img" />
                </div>
              </div>
            </div>
            <div className="col-md-4 col-12">
              <div className="filter-second-section-flex">
                <div className="filter-second-section-flex-img">
                  <img
                    src={
                      currentUser.profilePhoto.includes("https")
                        ? currentUser.profilePhoto
                        : currentUser.profilePhoto
                        ? PF
                        : "./images/noAvatar.png"
                    }
                    alt="img"
                  />
                </div>
                <div>
                  <h6 className="filter-second-section-h6">
                    {currentUser.username}
                  </h6>
                  <h6 className="filter-second-section-h6-2">
                    {currentUser.email?.replace("_google", "")}
                  </h6>
                  <div className="filter-second-section-post-flex">
                    <div>
                      <h6>{post.length}</h6>
                      <h5>posts</h5>
                    </div>
                    <div>
                      <h6>{follower.length}</h6>
                      <h5>Followers</h5>
                    </div>
                    <div>
                      <h6>{following.length}</h6>
                      <h5>Followings</h5>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="create-post-btn-3"
                style={{ marginLeft: "30px", marginTop: "20px" }}
              >
                <NavLink to="/photoshare">
                  <button className="filter-btn-f-m">Next</button>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Filter;
